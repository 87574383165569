/************************************************** GLOBAL */



:root {

  --black: #212529;

  --heading: #2E3A59;

  --lightblue: #3596e0;

  --label: #8F9BB3;

  --white: #fff;

  --light: #ECF0F2;

  --gradientred: linear-gradient(90deg, rgba(229, 45, 39, 1) 0%, rgba(179, 18, 23, 1) 100%);

}



html {

  max-height: 100vh;

  overflow: hidden;

}



* {

  font-family: 'DM Sans', sans-serif;

  transition: all 0.5s ease-in-out;

  outline: none;

}

body {
  margin: 0;
  padding: 0;
}



button:active,

[type=button]:not(:disabled),

[type=reset]:not(:disabled),

[type=submit]:not(:disabled),

button:not(:disabled) {

  border: 0 !important;

  outline: none !important;

}



.dropdown-menu {

  transition: none !important;

}



*:focus {

  box-shadow: none !important;

}



::-webkit-scrollbar {

  width: 16px;

  overflow: visible;

}



::-webkit-scrollbar-track {

  width: 0px;

  background-color: transparent;

}



::-webkit-scrollbar-thumb {

  background-color: #00000023;

  border-radius: 10px;

  overflow: visible;

  border: 6px solid transparent;

  background-clip: content-box;

}



::-webkit-scrollbar-thumb:hover {

  background-color: #00000041;

}



*::placeholder,

*:placeholder-shown {

  font-size: 14px;

  font-family: 'DM Sans', sans-serif;

}



.bs-placeholder .filter-option-inner-inner {

  color: var(--label) !important;

  opacity: .8 !important;

}



.filter-option-inner-inner {

  padding-left: 7px;

  padding-top: 3px;

  color: var(--black);

  opacity: 1;

  font-size: 100%;

  font-family: 'DM Sans', sans-serif;

}



.form-control {

  color: var(--black);

}



h1,

h2,

h3,

h4,

h5 {

  color: var(--heading);

  font-weight: bold;

}



h1 {

  font-size: 40px;

  line-height: 48px;

  letter-spacing: -1px;

}



h2 {

  font-size: 30px;

  line-height: 36px;

  letter-spacing: -1px;

}



h3 {

  font-size: 20px;

  line-height: 24px;

  letter-spacing: -1px;

}



h4 {

  font-size: 12px;

  line-height: 24px;

  letter-spacing: 1px;

  text-transform: uppercase;

}



h5 {

  font-size: 10px;

  line-height: 14px;

  letter-spacing: 1px;

}



hr {

  margin-top: 1rem;

  margin-bottom: 1rem;

  border: 0;

  width: 100%;

  border-top: 1px solid var(--light);

}



.h100vh {

  height: 100vh;

}



.mh100vh {

  min-height: 100vh;

}



.mw100vw {

  max-width: 100vw;

}



#preloader-overlay img {

  height: 20vh;

  width: auto;

}



#preloader-overlay {

  display: flex;

  position: absolute;

  z-index: 999;

  top: 0;

  right: 0;

  left: 0;

  bottom: 0;

  justify-content: center;

  align-items: center;

  width: 100vw;

  height: 100vh;

  background-color: rgba(255, 255, 255);

}



.btn-wms,

.page-item.active .page-link {

  border: 0;

  color: var(--light);

  background: rgb(44, 64, 140);

  background: linear-gradient(180deg, rgba(44, 64, 140, 1) 0%, rgba(50, 89, 167, 1) 100%) !important;

  outline: none !important;

}



.btn-wms:hover,

.page-item.active .page-link:hover {

  background: #152856;

  background: linear-gradient(180deg, rgba(21, 39, 85, 1) 0%, rgba(36, 87, 153, 1) 100%) !important;

}



.progress-bar {

  background-color: rgb(36, 87, 153);

}



.btn-wms-light {

  outline: none !important;

  border: 0;

  padding: 6px 5px 5px !important;

  color: var(--white);

  background: rgb(44, 64, 140);

  background: linear-gradient(180deg, rgba(164, 212, 232, .8) 0%, rgba(79, 162, 214, .8) 100%) !important;

}



.btn-wms img {

  margin-right: 10px;

}



.btn-wms-light img {

  margin-right: 0 !important;

  float: left;

}



.btn-expand img {

  width: auto;

  height: 24px;

  margin: 0 !important;

}



.btn-expand {

  display: flex;

  padding: 5px 10px !important;

  justify-content: center;

}



.btn-expand:hover div {

  margin-left: 10px;

}



.btn-expand div,

.btn-wms-light div {

  opacity: 0;

  display: block;

  transition: all 0.325s ease-in-out;

  width: 1px !important;

  height: 20px;

  overflow: hidden;

}



.btn-wms-light:hover {

  background: linear-gradient(180deg, rgba(164, 212, 232, 1) 0%, rgba(79, 162, 214, 1) 100%) !important;

}



.btn-expand:hover div,

.btn-wms-light:hover div {

  opacity: 1;

  width: 50px !important;

  height: 20px !important;

}



.btn-expand.wider:hover div {

  opacity: 1;

  width: 120px !important;

  height: 20px !important;

}



.btn-expand.wider2:hover div {

  opacity: 1;

  width: 160px !important;

  height: 20px !important;

}



#search-bar,

.title-bar {

  position: relative;

  /*fix dla cienia*/

  width: 100%;

  height: 80px;

  display: block;

  background: var(--white);

}



#search-bar,

.title-bar {

  position: relative;

  /*fix dla cienia*/

  z-index: 200;

  box-shadow: 0px -1.21081px 3.63243px rgba(0, 0, 0, 0.11), 0px 3.54px 14.5297px rgba(0, 0, 0, 0.1);

}



#search::placeholder {

  font-size: 19px;

  font-weight: 100;

  color: var(--label);

  padding-left: 15px;

}



.title-bar {

  z-index: 1;

}



.boxshadow {

  box-shadow: 0px -1.21081px 3.63243px rgba(0, 0, 0, 0.11), 0px 3.54px 14.5297px rgba(0, 0, 0, 0.1);

}



.btn {

  padding: 6px 10px 5px 5px;

}



.btn img {

  margin-right: 5px;

  margin-bottom: 1px;

}



.bg-lightgray {

  background-color: var(--light);

}



.color-gray {

  color: var(--label);

}



.color-black {

  color: var(--black);

}



#search-form .form-group {

  float: left !important;

  transition: all 0.625s ease-in-out;

  width: 250px;

  height: 50px;

  background-color: var(--white);

  /* box-shadow: 0 0px 10px rgba(0, 0, 0, 0.05) inset; */

  border-radius: 0px;

  border-bottom: 2px solid var(--white);

  /* border-image-source: linear-gradient(to left, var(--white), var(--white)); */

  margin-bottom: 0;

  display: flex;

  align-items: center;

  margin-left: 10px;

}



#search-form .form-group input.form-control {

  padding-right: 20px;

  border: 0 none;

  background: transparent;

  box-shadow: none;

  display: block;

  height: 100%;

  padding-left: 0;

}



#search-form input {

  caret-color: rgb(44, 64, 140);

}



#search-form img {

  cursor: pointer;

}



#search-form .form-group input.form-control::-webkit-input-placeholder {

  display: none;

}



#search-form .form-group input.form-control:-moz-placeholder {

  /* Firefox 18- */

  display: none;

}



#search-form .form-group input.form-control::-moz-placeholder {

  /* Firefox 19+ */

  display: none;

}



#search-form .form-group input.form-control:-ms-input-placeholder {

  display: none;

}



#search-form .form-group:hover,

#search-form .form-group.hover {

  width: 50%;

  /* min-width: 300px; */

  border-radius: 0;

  border-bottom: 2px solid rgb(0, 123, 255, 0.15) !important;

  /* border-image-slice: 1; */

  /* border-width: 0 0 2px 0; */

  /* border-image-source: linear-gradient(to left, #3596e0, rgb(44, 64, 140)); */

}



#search-form .form-group span.form-control-feedback {

  position: relative;

  top: 7px;

  right: -2px;

  z-index: 2;

  transform: scale(1.3);

  display: block;

  width: 64px;

  height: 50px;

  line-height: 34px;

  text-align: center;

  color: #3596e0;

  left: initial;

  font-size: 14px;

}



.autocomplete-form-cont {

  position: relative;

  top: -15px;

  left: 10px;

  z-index: 999;

}



.autocomplete-form-cont .autocomplete-form,

.autocomplete-form-cont-nofilters .autocomplete-form {

  box-shadow: 0px 2px 10px -2px #cedae0 !important;

}



.autocomplete-form-cont-nofilters {

  box-shadow: 0px 2px 10px -2px #cedae0 !important;

  position: relative;

  top: -15px;

  left: -15px;

  z-index: 999;

}



.autocomplete-form {

  position: absolute;

  background-color: var(--white);

  border-radius: 8px;

  max-width: 60vw;

  /* min-width: 300px; */

  width: 100%;

  display: block;

}



.ac-item {

  width: 100%;

  display: flex;

  padding: 5px;

  align-items: center;

  cursor: pointer;

}



.ac-name {

  width: 70%;

}



.ac-type {

  padding: 0;

}



.ac-type,

.ac-img,

.ac-link {

  display: flex;

  justify-content: center;

}



.ac-item .badge {

  width: 100%;

  text-align: center;

}



.ac-badge {

  background-color: var(--light);

  color: var(--heading);

}



.ac-badge img {

  height: 12px;

  cursor: pointer;

}



.ac-item:hover {

  background-color: var(--light);

}



.ac-options .dropdown-divider {

  margin: 0;

}



.ac-options {

  padding-bottom: 0px;

}



.ac-img img {

  width: 50px;

  max-width: 50px;

  max-height: 50px;

  margin: 10px;

}



.form-control {

  font-size: 14px;

}



#user-avatar,

.user-avatar {

  border-radius: 100px;

  max-width: 50px;

}



.user-avatar {

  margin: 0 20px;

}



.profile-submenu>*:not(:last-child) {

  margin-right: 20px;

}



.content-container {

  max-height: calc(100vh - 160px);

  z-index: -1;

}



#content-container-right {

  flex: 1;

}

#content-container-right.pr-2 {

  padding-right: 1.5rem !important;

}



.filter-collapse {

  float: right;

  background-color: var(--light);

  width: 50px;

  height: 50px;

  border-radius: 100px;

  padding: 10px;

  position: absolute;

  right: -25px;

  top: 15px;

}



#filter-toggle2 {

  display: none;

}



#filter-sidebar::-webkit-scrollbar {

  width: 0;

}

#filter-sidebar {

  height: auto;

  overflow-y: overlay !important;

  max-height: 100vh;

  overflow-x: hidden;

  height: calc(100vh - 160px);

}



#content-container-right {

  height: calc(100vh - 160px);

}



#filter-sidebar.toggled {

  margin-left: -22%;

}



#filter-toggle {

  cursor: pointer;

}



#filter-toggle-mobile,

#filter-toggle3 {

  display: none;

}



#filter-toggle.toggled img {

  transform: rotateZ(180deg);

  right: -50px;

}



.filter-clear {

  margin-top: auto;

  cursor: pointer;

}



.filter-clear p {

  margin: 0;

}



.filter-clear:hover {

  color: var(--black);

}



#date-filter-container .date-filter:not(:last-child) {

  margin-right: 20px;

  padding: 4px 10px;

  color: var(--label);

  cursor: pointer;

}



#current-filter {

  background-color: var(--light);

  padding: 4px 10px;

  border-radius: 5px;

}



#current-filter input {

  cursor: pointer;

  background: transparent;

  border: 0;

  font-size: 13px;

  width: 160px;

}



.rounded-10 {

  border-radius: 10px;

}



nav#pagination-container {

  display: flex;

  justify-content: center;

}



.pagination .page-link {

  width: 40px;

  height: 40px;

  border: 0;

  text-align: center;

  margin: 0 5px;

  padding: .55rem .75rem;

  border-radius: 50px !important;

}



.pagination .page-link.active {

  padding: .75rem .75rem;

}



.pagination {

  display: flex;

  align-items: center;

}



.pagination .page-item:first-child .page-link,

.pagination .page-item:last-child .page-link {

  width: 25px;

  height: 25px;

  padding: 2px 4px;

}





/************************************************** sidebar menu*/



#wmssidebar {

  display: flex;

  flex-wrap: nowrap !important;

  flex-direction: column;

  justify-content: flex-start;

  z-index: 100;

  padding-bottom: 30px;

}



.scrolling {

  overflow-y: auto !important;

  overflow-y: overlay !important;

}



.logo-section {

  display: flex;

  justify-content: center;

  align-items: center;

  width: 100%;

  height: 160px;

  box-shadow: 0px -1.21081px 3.63243px rgba(0, 0, 0, 0.11), 0px 3.54px 14.5297px rgba(0, 0, 0, 0.1);

}



.wms-logo {

  /* max-width: 80%; */

  height: 70%;

  margin-top: 10px;

}



.menu-wrapper {

  width: 100%;

  padding-top: 10px;

  display: flex;

  align-items: flex-start;

  flex-flow: wrap;

  justify-content: center;

}



.menu-wrapper a {

  width: 100%;

  text-decoration: none;

}



.menu-item {

  width: 80%;

  max-height: 60px;

  padding: 35px 5px 40px;

  margin: 10px auto 0;

  /* background: rgba(0, 0, 0, 0.11); */

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  margin-top: 10px;

  transition: all .2s ease-in-out;

  border-radius: 8px;

  cursor: pointer;

}



.menu-item h5 {

  opacity: 0.8;

  height: 1px;

  font-size: 12px;

  line-height: 16px;

  font-weight: 100;

  overflow: visible;

  color: var(--white);

  position: relative;

  top: 10px;

  text-align: center;

  letter-spacing: .2px;



}



.menu-item.active,

.menu-item:hover {

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.06) 100%);

  box-shadow: 0px 4px 4px rgba(79, 160, 215, 0.1), 0px 4px 0px rgba(255, 255, 255, 0.06), 0px 10px 18px rgba(25, 32, 56, 0.15);

  border-radius: 8px;

}



.menu-item.active h5,

.menu-item:hover h5 {

  opacity: 1;

  height: 1px;

  overflow: visible;

  text-shadow: 0px 4px 4px rgba(79, 160, 215, 0.2);

}





/************************************************** MOBILE MENU */



.onmobile,

.tableonmobile {

  display: none !important;

}



.tablenomobile {

  display: table-row;

}



.nomobile {

  display: block;

}



.wms-logo-mobile {

  display: none;

}



.burgermenu {

  background-color: transparent;

  z-index: 9999;

  border: none;

  cursor: pointer;

  display: flex;

  padding: 0;

  display: none;

  outline: none !important;

  position: fixed;

  top: 20px;

  right: 20px;

}



.bewhite {

  filter: invert(100%);

}



.line {

  fill: none;

  stroke: black;

  stroke-width: 6;

  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);

}



.line1 {

  stroke-dasharray: 60 207;

  stroke-width: 6;

}



.line2 {

  stroke-dasharray: 60 60;

  stroke-width: 6;

}



.line3 {

  stroke-dasharray: 60 207;

  stroke-width: 6;

}



.opened .line1 {

  stroke-dasharray: 90 207;

  stroke-dashoffset: -134;

  stroke-width: 6;

}



.opened .line2 {

  stroke-dasharray: 1 60;

  stroke-dashoffset: -30;

  stroke-width: 6;

}



.opened .line3 {

  stroke-dasharray: 90 207;

  stroke-dashoffset: -134;

  stroke-width: 6;

}





/************************************************** POWIADOMIENIA */



.dropdown-menu {

  box-shadow: 0px 2px 10px -2px #cedae0;

}



#dingding-container .dropdown-header {

  text-align: center;

  width: 300px;

  max-width: 100vw;

  box-shadow: 0px 2px 10px -2px #cedae0;

  border: 0;

  border-style: solid;

  border-bottom-width: 1px;

  -moz-border-image: -moz-linear-gradient(right, #E4E9F2, #a9b4ce, #a9b4ce, #E4E9F2) 1 20%;

  -o-border-image: -o-linear-gradient(right, #E4E9F2, #a9b4ce, #a9b4ce, #E4E9F2) 1 20%;

  border-image: linear-gradient(to right, #E4E9F2 5%, #a9b4ce 40%, #a9b4ce 60%, #E4E9F2 95%) 1 20%;

}



#notifications-count {

  display: inline-block;

  height: 25px;

  width: 25px;

  background: #3596e0;

  color: var(--white);

  text-align: center;

  line-height: 25px;

  border-radius: 100%;

  font-weight: bold;

}



.dingding-list .dingding {

  padding: 10px;

  border: 0;

}



.dingding-list>.dingding:not(:last-child) {

  border-style: solid;

  border-bottom-width: 1px;

  -moz-border-image: -moz-linear-gradient(right, white, #E4E9F2, #E4E9F2, white) 1 20%;

  -o-border-image: -o-linear-gradient(right, white, #E4E9F2, #E4E9F2, white) 1 20%;

  border-image: linear-gradient(to right, white 5%, #E4E9F2 40%, #E4E9F2 60%, white 95%) 1 20%;

}



.dingding.new {

  background-color: #eef1f7;

  /* border-bottom: 1px solid #a9b4ce; */

  border: 0;

  color: #142553;

  border-style: solid;

  border-bottom-width: 1px;

  -moz-border-image: -moz-linear-gradient(right, #eef1f7, #c0c9df, #c0c9df, #eef1f7) 1 20%;

  -o-border-image: -o-linear-gradient(right, #eef1f7, #c0c9df, #c0c9df, #eef1f7) 1 20%;

  border-image: linear-gradient(to right, #eef1f7 5%, #c0c9df 40%, #c0c9df 60%, #eef1f7 95%) 1 20% !important;

}



.notification-img {

  width: 30px;

  height: 30px;

  border-radius: 50px;

  margin-right: 10px;

}





/********************************************************** FLOAT LABEL */



#login-container {

  background: rgb(255, 255, 255);

  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgb(236, 243, 252) 100%);

}



.has-float-label {

  display: block;

  position: relative;

  width: 100%;

}



.has-float-label label,

.has-float-label>span {

  position: absolute;

  cursor: text;

  font-size: 12px;

  opacity: 1;

  -webkit-transition: all .2s;

  transition: all .2s;

  top: -.7em;

  left: .75rem;

  z-index: 3;

  line-height: 1;

  padding: 0 1px;

  border: 3px solid var(--white);

  background: var(--white);

  color: var(--label);

}



.readonly .has-float-label label,

.readonly .has-float-label>span {

  border: 3px solid var(--white);

  background: var(--white);

  color: var(--label);

  border-radius: 10px;

  padding: 0 6px;

}



.form-control:disabled,

.form-control[readonly] {

  border: 3px solid #bdbdbd !important;

}



.has-float-label label::after,

.has-float-label>span::after {

  content: " ";

  display: block;

  position: absolute;

  background: var(--white);

  height: 2px;

  top: 50%;

  left: -.2em;

  right: -.2em;

  z-index: -1;

}



.has-float-label .form-control::-webkit-input-placeholder {

  opacity: 1;

  -webkit-transition: all .2s;

  transition: all .2s;

}



.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {

  opacity: 0;

}



.has-float-label .form-control:placeholder-shown:not(:focus)+* {

  font-size: 100%;

  opacity: .8;

  top: 0.75em;

}



textarea.form-control {

  height: auto !important;

}



.input-group .has-float-label {

  display: table-cell;

}



.input-group .has-float-label .form-control {

  border-radius: .25rem;

}



.form-control,

.custom-select {

  border: 2px solid #cedae0;

  /* border: 3px solid #eef1f7; */

  background: var(--white);

  height: calc(1.5em + .75rem + 10px) !important;

}



.form-control2 {

  padding: 10px 0;

}



.form-frame {

  /* border: 3px solid #eef1f7; */

  border: 2px solid #cedae0;

  background: var(--white);

}



.bootstrap-select .btn-light {

  border: 2px solid #cedae0 !important;

  /* border: 3px solid #eef1f7 !important; */

  background-color: var(--white) !important;

}



body .bootstrap-select .dropdown-toggle:focus,

body .bootstrap-select>select.mobile-device:focus+.dropdown-toggle {

  outline: none !important;

}



.bootstrap-select .dropdown-toggle {

  height: 100%;

}

/********************************************************** ANIMACJE */



.animated {

  -webkit-animation-duration: 500ms;

  animation-duration: 500ms;

  -webkit-animation-fill-mode: both;

  animation-fill-mode: both;

}



@-webkit-keyframes fadeInDown {

  from {

    opacity: 0;

    -webkit-transform: translate3d(0, -10%, 0);

    transform: translate3d(0, -10%, 0);

  }

  to {

    opacity: 1;

    -webkit-transform: none;

    transform: none;

  }

}



@keyframes fadeInDown {

  from {

    opacity: 0;

    -webkit-transform: translate3d(0, -10%, 0);

    transform: translate3d(0, -10%, 0);

  }

  to {

    opacity: 1;

    -webkit-transform: none;

    transform: none;

  }

}



.fadeInDown {

  -webkit-animation-name: fadeInDown;

  animation-name: fadeInDown;

}



@-webkit-keyframes fadeOutUp {

  from {

    opacity: 1;

  }

  to {

    opacity: 0;

    -webkit-transform: translate3d(0, -10%, 0);

    transform: translate3d(0, -10%, 0);

  }

}



@keyframes fadeOutUp {

  from {

    opacity: 1;

  }

  to {

    opacity: 0;

    -webkit-transform: translate3d(0, -10%, 0);

    transform: translate3d(0, -10%, 0);

  }

}



.fadeOutUp {

  -webkit-animation-name: fadeOutUp;

  animation-name: fadeOutUp;

}



@-webkit-keyframes fadeOut {

  from {

    opacity: 1;

  }

  to {

    opacity: 0;

  }

}



@keyframes fadeOut {

  from {

    opacity: 1;

  }

  to {

    opacity: 0;

  }

}



.fadeOut {

  -webkit-animation-name: fadeOut;

  animation-name: fadeOut;

}



@-webkit-keyframes fadeIn {

  from {

    opacity: 0;

  }

  to {

    opacity: 1;

  }

}



@keyframes fadeIn {

  from {

    opacity: 0;

  }

  to {

    opacity: 1;

  }

}



.fadeIn {

  -webkit-animation-name: fadeIn;

  animation-name: fadeIn;

}



@-webkit-keyframes zoomIn {

  from {

    opacity: 0;

    -webkit-transform: scale3d(0.3, 0.3, 0.3);

    transform: scale3d(0.3, 0.3, 0.3);

  }

  50% {

    opacity: 1;

  }

}



@keyframes zoomIn {

  from {

    opacity: 0;

    -webkit-transform: scale3d(0.3, 0.3, 0.3);

    transform: scale3d(0.3, 0.3, 0.3);

  }

  50% {

    opacity: 1;

  }

}



.zoomIn {

  -webkit-animation-name: zoomIn;

  animation-name: zoomIn;

}



@-webkit-keyframes zoomOut {

  from {

    opacity: 1;

  }

  50% {

    opacity: 0;

    -webkit-transform: scale3d(0.3, 0.3, 0.3);

    transform: scale3d(0.3, 0.3, 0.3);

  }

  to {

    opacity: 0;

  }

}



@keyframes zoomOut {

  from {

    opacity: 1;

  }

  50% {

    opacity: 0;

    -webkit-transform: scale3d(0.3, 0.3, 0.3);

    transform: scale3d(0.3, 0.3, 0.3);

  }

  to {

    opacity: 0;

  }

}



.zoomOut {

  -webkit-animation-name: zoomOut;

  animation-name: zoomOut;

}





/************************************************** LOGOWANIE */



.login {

  min-height: 100vh;

}



.blue-bg {

  background-size: cover;

  background-position: center right;

  display: flex;

  flex-wrap: wrap;

  max-height: 100vh;

  overflow: hidden;

}



.login-bg-image {

  /* max-width: 100%; */

  width: 140%;

  align-self: flex-end;

  height: auto;

  /* padding-left: 100px; */

  margin-left: -20%;

}



.login-bg-logo {

  width: 200px;

  align-self: flex-start;

  margin: 10vh 0 0 10vh;

  height: auto;

}



.login-client-logo {

  margin: 10vh 0 0 0;

  width: 300px;

}





/************************************************** DOKUMENTY */



#table-documents tr td:last-child {

  width: 75px;

}



.table-legend {

  background: var(--white);

  border-radius: 8px;

  padding: 10px 15px;

  margin-bottom: 20px;

  box-shadow: 0px 2px 10px -2px #cedae0;

  display: flex;

  justify-content: space-between;

  align-items: flex-start;

}



.filter-top-bar {

  background: var(--white);

  width: 100%;

  border-radius: 8px;

  padding: 10px 15px;

  margin-bottom: 20px;

  box-shadow: 0px 2px 10px -2px #cedae0;

  display: flex;

  justify-content: space-around;

  align-items: center;

}



.table-legend a {

  text-decoration: none;

  color: inherit;

}



.table-legend div {

  display: flex;

  align-items: center;

}

.table-legend div a {

  display: flex;

  flex-wrap: wrap;

  justify-content: center;

  align-items: center;

  text-align: center;

}



.table-legend img {

  margin-right: 10px;

}



.table {

  box-shadow: 0px 2px 10px -2px #cedae0;

}



.product-missing th,

.product-missing td {

  border-bottom: 1px solid #B31217;

  color: var(--light)
}



.product-missing td:last-child img:last-child {

  border: 2px solid #6e090c;

  border-radius: 50px;

  box-shadow: inset 0 0 20px #b61418, inset 0 0 20px #b71419, inset 0 0 20px #bb1619;

}



.product-missing h3 {

  color: var(--light);

}



.product-missing {

  background: var(--gradientred);

}



.last-visited {

  border-left: 20px solid var(--lightblue);

}





/************************************************** FORMULARZE DODAWANIA */



.step-back {

  transition: all 0.3s;

  cursor: pointer;

  border-radius: 50px;

  opacity: 0.4;

}



.step-back img {

  transform: rotate(90deg);

  position: relative;

  top: -1px;

  left: -1px;

}



.step-back:hover {

  opacity: 1;

  background-color: #eef1f7;

}



.add-tab {

  display: flex;

  align-items: center;

  flex-wrap: nowrap;

}



.add-tab div {

  display: flex;

  width: 100%;

  align-items: center;

  justify-content: center;

  flex-direction: column;

  flex-wrap: wrap;

  padding: 30px 0;

  cursor: pointer;

}



.add-tab img {

  float: right;

}



.add-tab.active {

  background-color: var(--light);

}





/* .add-tab:first-child {

  border-radius: 20px 0 0 0;

}



.add-tab:last-child {

  border-radius: 0 20px 0 0;

}



#tabs-header,

.rounded-20-top {

  border-radius: 20px 20px 0 0;

} */



#tabs-header .add-tab h3 {

  margin-bottom: 0;

  margin-top: 5px;

}



#tabs-header .add-tab:not(:last-child) {

  border-left: 1px solid #E4E9F2;

}



.save-tab img {

  margin-right: 0 !important;

}



.save-tab {

  color: var(--white);

  cursor: pointer;

}



.next-step img {

  opacity: 0.5;

  transform: rotate(-90deg);

  height: 14px;

}



.category-frame {

  max-height: 227px;

}



.upload-photo {

  display: block;

  /* background: var(--light) url('../gfx/no-image.png') center no-repeat; */

  max-width: 100%;

  min-height: 100%;

  /* min-width: 280px; */

}



.upload-btn {

  opacity: 0;

  /* max-width: 100%; */

  /* width: 100%; */

  /* padding-bottom: 50%; */

  /* min-width: 280px;

  min-height: 280px; */

  display: block;

  cursor: pointer;

}



.upload-btn:hover {

  opacity: 1;

}



.upload-btn img {

  width: 100px;

}



.h-fit {

  height: fit-content;

}



.is-invalid {

  border-top: 3px solid #eef1f7 !important;

  border-left: 3px solid #eef1f7 !important;

  border-right: 3px solid #eef1f7 !important;

  border-bottom: 3px solid #b101019d !important;

}



.is-invalid .btn-light {

  border: 0 !important;

}



.is-invalid-alert {

  color: #b101019d;

  font-size: 11px;

}



.step-status {

  float: right;

}



.input-group-append .input-group-text {

  border-radius: 0 .5rem .5rem 0;

  border: 0;

  color: var(--heading);

  background-color: var(--light);

}



.date-range input:first-child {

  min-width: 75%;

}



.date-range {

  width: 100%;

  display: flex;

  flex-wrap: nowrap;

}



.range-selector {

  max-height: 43px;

  height: 43px;

}



.range-selector .form-control,

.range-selector .custom-select {

  height: auto !important;

  background: transparent;

}

.input-group-append,
.input-group-prepend {

  z-index: 99;

}

.product-image img {

  object-fit: cover;

  max-width: 100%;

  max-height: auto;

}



.product-preview-codes span {

  color: var(--label);

  margin-right: 5px;

}



.specs-list span {

  float: right;

  font-weight: bold;

}



.specs-list {

  text-align: left;

  width: 100%;

  margin-bottom: 6px;

}





/*************************************************************************** TABELE */



.table td,

.table th {

  vertical-align: middle;

}



.table thead th {

  white-space: nowrap;

}

.table thead th img {

  margin-left: 10px;

  display: inline-block;

}



.sort-topbar:hover,

.filter-clear-topbar:hover {

  cursor: pointer;

  background-color: var(--light) !important;

}



.sort-topbar img {

  opacity: 0;

  margin-right: 10px;

}



.sort-topbar {

  padding-left: 10px;

  padding-right: 30px;

}



.sort-topbar.active img,

.sort-topbar:hover img {

  opacity: 1;

}



#table-warehouses tr td:first-child {

  text-align: center;

}



th {

  font-weight: normal !important;

}



.table-hover {

  cursor: pointer;

}



.table-dark td,

.table-dark th {

  border: 0;

}



tr.row-inactive {

  color: var(--label);

  background-color: var(--light);

}



#table-barcodes h3 {

  text-align: right;

}



#table-barcodes h3 input {

  color: var(--heading);

  font-size: 14px;

  font-weight: bold;

  text-align: right;

  position: relative;

  /* width: 30%; */

}


h4.full-time span {

  color: var(--label);

  margin-right: 5px;

  font-weight: 100;

  font-size: 10px;

}



#table-barcodes h3 span {

  color: var(--label);

  margin-right: 5px;

  font-weight: 100;

  font-size: 14px;

  float: left;

}



#table-stock h3 span,

.full-time span {

  color: var(--label);

  margin-right: 10px;

  font-weight: 100;

  font-size: 14px;

}



.full-time2 span {

  color: var(--label);

  margin-left: 10px;

  font-weight: 100;

}



#table-stock h3 {

  font-size: 16px;

}



.progress-percentage {

  float: left;

  margin-right: 20px;

}



.custom-control-label {

  color: var(--label);

}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-dialog .custom-control-label {

  padding-top: 2px;

}



#table-users .row-inactive td:first-child img {

  display: none;

}



.block-preloader img {

  max-height: 120px;

  height: 120px;

  margin: 30px;

}

.block-preloader {

  display: flex;

  width: 100%;

  height: 100%;

  align-items: center;

  justify-content: center;

  border-radius: 10px;

  /* background-color: var(--light); */

  background: linear-gradient(170deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.01) 10%, rgba(0, 0, 0, 0.1) 100%);

}





/****************************************************raporty*/



.report-tile {

  background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.2) 100%);

  box-shadow: 0px 4px 4px rgba(79, 160, 215, 0.1), 0px 4px 0px rgba(255, 255, 255, 0.1), 0px 10px 18px rgba(25, 32, 56, 0.15);

  border: 0;

  border-radius: 8px;

  width: 100%;

  max-width: 200px;

  min-width: 140px;

  height: 160px;

  padding: 30px 20px;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: space-between;

  transition: all .2s ease-in-out;

  cursor: pointer;

  outline: none !important;

}



.report-tile-container:hover .report-tile,

.report-tile-container.active .report-tile {

  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, .04) 100%);

  border-radius: 8px;

  box-shadow: 0px 4px 4px rgba(79, 160, 215, 0.05), 0px 4px 0px rgba(255, 255, 255, 0.03), 0px 10px 18px rgba(25, 32, 56, 0.05);

}



.report-tile img {

  height: 30px;

  width: auto;

}



.report-tile h5 {

  font-size: 14px;

  letter-spacing: -.2px;

  font-weight: 100;

}



.report-tiles>.report-tile-container {

  margin: 10px;

  width: 8vw;

}



.settings-container .settings-form,

.report-container .report-form {

  display: flex;

  align-items: center;

  justify-content: center;

  width: 100%;

}



#report-form-container .fade:not(.show) {

  height: 0 !important;

}



#report-form-container.tab-content {

  border: 0 !important;

}



.report-tiles {

  width: auto;

  flex-wrap: nowrap;

  /* wrap na tablecie!*/

  margin: 0 -10px;
  text-align: center;

}



.report-container .report-form.niewidoczny,

.settings-container .settings-form.niewidoczny,

.report-container .niewidoczny {

  display: none !important;

}





/**********************************************************zglos blad*/



.prio2 {

  background-color: rgba(179, 18, 23, .2);

}



.prio3 {

  background-color: rgba(179, 18, 23, .6);

}



.prio4 {

  background-color: rgba(179, 18, 23, 1);

  font-weight: bold;

  color: var(--light)
}



input[type="file"] {

  display: none;

}



.file-upload {

  cursor: pointer;

}



.user-photo-container {

  padding: 0 7% 16px 7%;

  display: flex;

  align-items: center;

}

.user-photo-cont {

  max-height: 250px;

  max-width: 250px;

  border-radius: 500px;

  overflow: hidden;

}



.user-photo {

  width: 100%;

  height: auto;

  border-radius: 200px;

}



.upload-user-photo .upload-btn {

  position: absolute;

  left: 0;

  right: 0;

  top: 0;

  bottom: 0;

  border-radius: 250px;

  margin: 0;

}



.upload-user-photo {

  position: relative;

  padding: 0;

  border-radius: 500px;

  height: auto;

  overflow: hidden;

}





/***************************************************************ustawienia */



.settings .report-tile {

  max-width: initial !important;

}



.settings-form .readonly:not(:first-child) label {

  display: none;

}



.report-tiles-mobile {

  display: none;

}





/***************************************************************produkt */



.size-specs div {

  margin: 0 4px;

  font-weight: bold;

}



.specs-list-special div img {

  margin-top: -4px;

  margin-right: 10px;

}



.specs-list-special div {

  font-size: 13px;

  margin: 5px 1%;

  width: 48%;

}





/*************************************************************** animowane zakladki  */



.tabs-container button {

  outline: none;

  border: 0;

  margin: 10px;

}



.tabs-container button.active {

  background: #152856 !important;

}

.tabs-container a.active {

  background: #152856 !important;

}

.tabs-container a {
  color: rgb(0,0,0);
  margin: 10px;
  background: rgb(239, 239, 239) !important;

}


.tab-content {

  border: 1px solid var(--light);

  border-radius: 8px;

  padding: 30px 0px 10px 0px;

  margin-top: -45px;

}



.tab-content {

  overflow: hidden;

}



.document-details .has-float-label label,

.document-details .has-float-label>span,

.noborderinputlabel .has-float-label label,

.noborderinputlabel .has-float-label>span {

  left: 0;

  border: 0;

}



.document-details h5 span {

  color: var(--label);

}



.document-details h5 {

  margin-bottom: 2px !important;

}



.worker-img img {

  width: 80px;

  max-width: 80px;

}



body.modal-open .container-fluid.bg-lightgray {

  /* -webkit-filter: blur(4px);

  -moz-filter: blur(4px);

  -o-filter: blur(4px);

  -ms-filter: blur(4px);

  filter: blur(4px); */

}



.btn-save img {

  max-height: 20px;

}



#table-barcodes {

  display: table;

}



#table-barcodes .btn-outline-danger:hover {

  color: #b61418;

}



#stock-table .btn-sm {

  width: 100%;

  color: #949fbd;

}



#stock-table .btn-sm:hover {

  color: var(--heading);

}



.nooverflow {

  overflow: hidden;

}



.link-arrow {

  transform: rotate(-90deg);

  max-width: 21px;

  margin-bottom: 4px;

  margin-right: 20px;

  opacity: 0.3;

}



.subcategory-btn {

  margin-left: 10px;

}



.cat-arrow {

  transform: rotate(0deg);

  opacity: 0.8;

  max-width: 16px;

  margin-bottom: 2px;

}



.cat-arrow.collapsed {

  transform: rotate(-90deg);

  opacity: 0.6;

}



.product-categories .category-adder {

  height: 150px;

  max-height: 220px;

}

.product-category-adder .category-adder {
  height: 228px;

  max-height: 228px;
}



.category-adder ul {

  list-style: none;

}



.modal-dialog {

  height: 100vh;

  width: 100vw;

  display: flex;

  align-items: center;

  margin-top: 0;

}



.modal-body .category-adder .custom-control {

  padding-left: 20px !important;

}



.modal-body .category-adder li ul {

  width: 100%;

  margin-top: 20px;

}



.modal-body .category-adder li:hover {

  background-color: var(--light);

}



.modal-body .category-adder li:hover ul {

  background-color: var(--white);

}



.modal-body .category-adder li {

  display: flex;

  flex-wrap: wrap;

  padding-bottom: 5px;

  padding-top: 5px;

  justify-content: flex-start;

  align-items: center;

}

.modal-body .category-adder li img{
  margin-left: 10px;
}



.modal-body .category-adder .subcategories li {

  padding-left: 70px;

}



.modal-body .category-adder .subcategories {

  margin-left: -100px;

  width: 130%;

}



.modal-body .category-adder .add-btn-cat button {

  width: 100%;

  min-width: auto !important;

  text-align: center;

  margin: 0;

}



.modal-body .category-adder .cat-edit-buttons img {

  height: 20px;

  margin: 3px 6px 3px 3px;

}



.modal-body .category-adder .cat-edit-buttons {

  opacity: 0;

  margin-right: 15px !important;

}

#cat-modal .custom-control-input{
  position: relative;
  z-index: 1;
  left: -8px;
}


.modal-body .category-adder li:hover>.cat-edit-buttons {

  opacity: 1;

}



.modal-body .category-adder .cat-edit-buttons button {

  padding-right: 10px;

}



.card-text {

  cursor: pointer;

  padding: 15px 20px;

  margin: 0;

}



.card-text:hover {

  background-color: var(--light);

}



#permissions button {

  background: none;

  border: 0;

  border-radius: 0;

  color: var(--heading);

  font-size: 20px;

  padding: 20px 0;

}



#permissions button[aria-expanded="true"] {

  border-right: 5px solid var(--lightblue);

  background-color: var(--light);

  font-weight: bold;

}





/******************************************************************kołowy wykres */



.progress--circle {

  position: relative;

  display: inline-block;

  margin: 1rem;

  width: 80px;

  height: 80px;

  border-radius: 50%;

  background-color: #ddd;

}



.progress--circle:before {

  content: "";

  position: absolute;

  top: 5px;

  left: 5px;

  width: 70px;

  height: 70px;

  border-radius: 50%;

  background-color: white;

}



.progress--circle:after {

  content: "";

  display: inline-block;

  width: 100%;

  height: 100%;

  border-radius: 50%;

  background-color: #63B8FF;

}



.progress__number {

  position: absolute;

  top: 50%;

  width: 100%;

  line-height: 1;

  margin-top: -0.75rem;

  text-align: center;

  font-size: 1.5rem;

  color: #777;

}



.progress--circle.progress--0:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(90deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--5:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(108deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--10:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(126deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--15:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(144deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--20:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(162deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--25:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(180deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--30:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(198deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--35:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(216deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--40:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(234deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--45:after {

  background-image: linear-gradient(90deg, #ddd 50%, transparent 50%, transparent), linear-gradient(252deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--50:after {

  background-image: linear-gradient(-90deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--55:after {

  background-image: linear-gradient(-72deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--60:after {

  background-image: linear-gradient(-54deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--65:after {

  background-image: linear-gradient(-36deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--70:after {

  background-image: linear-gradient(-18deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--75:after {

  background-image: linear-gradient(0deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--80:after {

  background-image: linear-gradient(18deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--85:after {

  background-image: linear-gradient(36deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--90:after {

  background-image: linear-gradient(54deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--95:after {

  background-image: linear-gradient(72deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}



.progress--circle.progress--100:after {

  background-image: linear-gradient(90deg, #63B8FF 50%, transparent 50%, transparent), linear-gradient(270deg, #63B8FF 50%, #ddd 50%, #ddd);

}





/*************************************************oś czasu kokpit */



.timeline {

  position: relative;

  padding-left: 4rem;

  margin: 0 0 0 30px;

  color: var(--black);

}



.timeline:before {

  content: "";

  position: absolute;

  left: 0;

  top: 10px;

  width: 4px;

  height: 100%;

  background: var(--light);

}



.timeline .timeline-container {

  position: relative;

  margin-bottom: 2.5rem;

}



.timeline .timeline-container .timeline-icon {

  position: absolute;

  left: -88px;

  top: 4px;

  width: 50px;

  height: 50px;

  border-radius: 50%;

  text-align: center;

  font-size: 2rem;

  background: #4f537b;

  overflow: hidden;

}



.timeline .timeline-container .timeline-icon img {

  width: 50%;

  height: 50%;

  position: absolute;

  left: 50%;

  top: 50%;

  transform: translate(-50%, -50%);

}



.timeline .timeline-container .timeline-icon .avatar {

  width: 100%;

  height: 100%;

  font-size: 0;

}



.timeline .timeline-container .timeline-body {

  background: var(--light);

  border-radius: 10px;

  padding: 15px 20px 5px;

  /* box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1); */

  cursor: pointer;

}



.timeline .timeline-container .timeline-body:before {

  content: "";

  background: inherit;

  width: 20px;

  height: 20px;

  display: block;

  position: absolute;

  left: -10px;

  transform: rotate(45deg);

  border-radius: 0 0 0 2px;

}



.timeline .timeline-container .timeline-body p,

.timeline .timeline-container .timeline-body .timeline-title {

  margin-bottom: 10px;

}



.timeline .timeline-container .timeline-body .timeline-title .badge {

  background: #4f537b;

  padding: 4px 8px;

  border-radius: 3px;

  color: #fff;

  font-size: 12px;

  font-weight: bold;

  white-space: normal;

  max-width: 100%;

}



.timeline .timeline-container .timeline-body .timeline-subtitle {

  font-weight: 300;

  font-style: italic;

  opacity: 0.4;

  margin-top: 16px;

  font-size: 11px;

  margin-top: 0 !important;

  float: right;

}



.timeline .timeline-container.primary .badge,

.timeline .timeline-container.primary .timeline-icon {

  background: #1d8cf8 !important;

}



.timeline .timeline-container.info .badge,

.timeline .timeline-container.info .timeline-icon {

  background: #11cdef !important;

}



.timeline .timeline-container.success .badge,

.timeline .timeline-container.success .timeline-icon {

  background: #00bf9a !important;

}



.timeline .timeline-container.warning .badge,

.timeline .timeline-container.warning .timeline-icon {

  background: #ff8d72 !important;

}



.timeline .timeline-container.danger .badge,

.timeline .timeline-container.danger .timeline-icon {

  background: #fd5d93 !important;

}



.author {

  font-family: inherit;

  padding: 3em;

  text-align: center;

  width: 100%;

  color: white;

}



.author a:link,

.author a:visited {

  color: white;

}



.author a:link:hover,

.author a:visited:hover {

  text-decoration: none;

}



.author .btn:link,

.author .btn:visited {

  margin-top: 1em;

  text-decoration: none;

  display: inline-block;

  font-family: inherit;

  font-weight: 100;

  color: white;

  text-align: center;

  vertical-align: middle;

  user-select: none;

  background-color: black;

  padding: 1.5em 2rem;

  border-radius: 1em;

  transition: 0.5s all;

}



.author .btn:link:hover,

.author .btn:link:focus,

.author .btn:link:active,

.author .btn:visited:hover,

.author .btn:visited:focus,

.author .btn:visited:active {

  background-color: #1a1a1a;

}





/**********************************upload photo crop */



label.cabinet {

  display: block;

  cursor: pointer;

}



label.cabinet input.file {

  position: relative;

  height: 100%;

  width: auto;

  opacity: 0;

  -moz-opacity: 0;

  filter: progid: DXImageTransform.Microsoft.Alpha(opacity=0);

  margin-top: -30px;

}



#upload-demo {

  width: 250px;

  height: 250px;

  padding-bottom: 25px;

}



.user-photo-cont * {

  transition: all 0s;

}





/********************************************************************modale*/



.modal-confirm {

  color: var(--label);

  width: 400px;

}



.modal-confirm .modal-content {

  padding: 20px;

}



.modal-dialog .modal-content {

  border-radius: 5px;

  border: none;

  text-align: center;

  font-size: 14px;

}



.modal-dialog .modal-header {

  border-bottom: none;

  position: relative;

}



.modal-dialog h4 {

  text-align: center;

  font-size: 26px;

}



.modal-dialog .modal-body {

  color: #999;

}



.modal-dialog .modal-footer {

  border: none;

  text-align: center;

  border-radius: 5px;

  font-size: 13px;

  padding: 10px 15px 25px;

}



.modal-dialog .icon-box {

  width: 100%;

  text-align: center;

}



.modal-dialog .modal-footer a {

  color: #999;

}



.modal-dialog .modal-footer,

.modal-dialog .modal-header {

  justify-content: center;

}



.modal-dialog .modal-body {

  color: #999;

  max-height: 60vh;

  overflow-y: auto;

}



.modal-dialog .btn,

.modal-dialog .btn:active {

  color: #fff;

  border-radius: 4px;

  background: var(--label);

  text-decoration: none;

  transition: all 0.4s;

  line-height: normal;

  min-width: 120px;

  border: none;

  min-height: 40px;

  border-radius: 3px;

  margin: 0 5px;

}



.modal-dialog .btn-secondary {

  background: #c1c1c1;

}



.modal-dialog .btn-secondary:hover,

.modal-dialog .btn-secondary:focus {

  background: #a8a8a8;

}



.modal-dialog .btn-danger {

  background: #f15e5e;

}



.modal-dialog .btn-danger:hover,

.modal-dialog .btn-danger:focus {

  background: #ee3535;

}



.modal-delete-img {

  width: auto;

  height: 80px;

  margin: 0 auto;

}



.modal-dialog button.dropdown-toggle {

  min-height: 0px;

  margin: 0 !important
}





/******************************************* powiadomienia*/



.el-toast {

  align-items: center;

  bottom: 0;

  display: flex;

  flex-direction: column;

  list-style: none;

  margin: 16px;

  opacity: 1;

  padding: 0 0 10px;

  position: fixed;

  right: 0;

  transition: opacity 150ms cubic-bezier(0.5, 0, 0.1, 1);

  width: 350px;

  border-radius: 10px;

}



.el-toast--paused {

  opacity: 0.9;

}



.el-toast button,

.el-toast button:focus,

.el-toast button:hover {

  background-color: transparent;

  border: 0;

  cursor: pointer;

  outline: none;

}



.el-toast__item:not(:first-child) {

  margin-top: 8px;

}



.el-toast__item:nth-child(n+4) {

  bottom: 0;

  max-height: 40px;

  position: absolute;

}



.el-toast__item:nth-child(n+4) .el-toast__icon,

.el-toast__item:nth-child(n+4) .el-toast__progress,

.el-toast__item:nth-child(n+4) .el-toast__description,

.el-toast__item:nth-child(n+4) .el-toast__action {

  opacity: 0;

}



.el-toast__item:nth-child(n+6) {

  opacity: 0;

  transform: translateY(0) scale(0.85);

  z-index: 1970;

}



.el-toast__item:nth-child(4) {

  background-color: #595959;

  transform: translateY(-4px) scale(0.95);

  z-index: 1990;

}



.el-toast__item:nth-child(5) {

  background-color: #767676;

  transform: translateY(0) scale(0.9);

  z-index: 1980;

}



.el-toast__item {

  animation: append-in 300ms cubic-bezier(0, 0, 0.25, 1) forwards;

  background-color: var(--heading);

  border-radius: 10px;

  color: #fff;

  display: grid;

  grid-template: [header] "icon description close"auto [body] "icon action close"auto [footer] "progress progress progress"auto/38px 1fr 38px;

  max-height: 400px;

  overflow: hidden;

  position: relative;

  transform-origin: center bottom;

  transition: transform 300ms cubic-bezier(0, 0, 0.25, 1), background 300ms cubic-bezier(0, 0, 0.25, 1), opacity 300ms cubic-bezier(0, 0, 0.25, 1), max-height 300ms cubic-bezier(0, 0, 0.25, 1);

  user-select: none;

  width: 100%;

  z-index: 2000;

}



@keyframes append-in {

  from {

    max-height: 0;

    opacity: 0;

    transform: scale(0.9);

  }

}



@keyframes append-out {

  to {

    max-height: 0;

    opacity: 0;

    transform: scale(0.9);

  }

}



.el-toast__item--danger .el-toast__action button,

.el-toast__item--danger .el-toast__action a,

.el-toast__item--danger .el-toast__action i,

.el-toast__item--danger .el-toast__description button,

.el-toast__item--danger .el-toast__description a,

.el-toast__item--danger .el-toast__description i,

.el-toast__item--danger .el-toast__icon button,

.el-toast__item--danger .el-toast__icon a,

.el-toast__item--danger .el-toast__icon i {

  color: #DF6962;

}



.el-toast__item--danger .el-toast__bar {

  background-color: #D64038;

}



.el-toast__item--alert .el-toast__action button,

.el-toast__item--alert .el-toast__action a,

.el-toast__item--alert .el-toast__action i,

.el-toast__item--alert .el-toast__description button,

.el-toast__item--alert .el-toast__description a,

.el-toast__item--alert .el-toast__description i,

.el-toast__item--alert .el-toast__icon button,

.el-toast__item--alert .el-toast__icon a,

.el-toast__item--alert .el-toast__icon i {

  color: #F3CC6B;

}



.el-toast__item--alert .el-toast__bar {

  background-color: #EFBC3C;

}



.el-toast__item--success .el-toast__action button,

.el-toast__item--success .el-toast__action a,

.el-toast__item--success .el-toast__action i,

.el-toast__item--success .el-toast__description button,

.el-toast__item--success .el-toast__description a,

.el-toast__item--success .el-toast__description i,

.el-toast__item--success .el-toast__icon button,

.el-toast__item--success .el-toast__icon a,

.el-toast__item--success .el-toast__icon i {

  color: #54A668;

}



.el-toast__item--success .el-toast__bar {

  background-color: #54A668;

}



.el-toast__item--info .el-toast__action button,

.el-toast__item--info .el-toast__action a,

.el-toast__item--info .el-toast__action i,

.el-toast__item--info .el-toast__description button,

.el-toast__item--info .el-toast__description a,

.el-toast__item--info .el-toast__description i,

.el-toast__item--info .el-toast__icon button,

.el-toast__item--info .el-toast__icon a,

.el-toast__item--info .el-toast__icon i {

  color: #71C3DD;

}



.el-toast__item--info .el-toast__bar {

  background-color: #48B1D3;

}



.el-toast__item--removing {

  animation: append-out 300ms cubic-bezier(0.25, 0, 1, 1) forwards;

}



.el-toast__icon {

  display: flex;

  font-size: 1.4rem;

  grid-area: icon;

  justify-content: center;

  padding: 12px 8px 12px 12px;

}



.el-toast__description {

  grid-area: description;

  line-height: 1.5;

  margin: 10px 0;

  max-height: 200px;

  overflow-x: auto;

  padding: 0 8px;

}



.el-toast__description button:hover,

.el-toast__description button:focus {

  text-decoration: underline;

}



.el-toast__close {

  display: flex;

  grid-area: close;

  justify-content: center;

}



.el-toast__close button {

  color: #fff;

  display: block;

  height: 38px;

  padding: 10px 8px 8px;

  text-align: center;

  width: 100%;

}



.el-toast__action {

  grid-column: action;

}



.el-toast__action button,

.el-toast__action a {

  display: inline-block;

  padding: 0 8px 10px;

  text-transform: uppercase;

}



.el-toast__action button:hover,

.el-toast__action button:focus,

.el-toast__action a:hover,

.el-toast__action a:focus {

  text-decoration: underline;

}



.el-toast__progress {

  background-color: rgba(225, 227, 230, 0.15);

  grid-column: progress;

  height: 4px;

  width: 100%;

  z-index: 20;

}



.el-toast__progress--hide {

  display: none;

}



.el-toast__bar {

  bottom: 0;

  display: block;

  height: 4px;

  position: absolute;

  transition: width 100ms cubic-bezier(0.5, 0, 0.1, 1);

  width: 100%;

  z-index: 10;

}



@media only screen and (max-width: 720px) {

  .el-toast {

    margin: 16px 0;

    max-height: 400px;

    padding: 10px 0px;

    width: 100%;

  }

  .el-toast__item {

    width: calc(100% - 30px);

  }

  .el-toast__item:not(:first-child) {

    margin-top: 8px;

  }

  .el-toast__item:nth-child(n+2) {

    bottom: 0;

    max-height: 40px;

    position: absolute;

  }

  .el-toast__item:nth-child(n+2) .el-toast__icon,

  .el-toast__item:nth-child(n+2) .el-toast__progress,

  .el-toast__item:nth-child(n+2) .el-toast__description,

  .el-toast__item:nth-child(n+2) .el-toast__action {

    opacity: 0;

  }

  .el-toast__item:nth-child(n+4) {

    opacity: 0;

    transform: translateY(0) scale(0.9);

    z-index: 1970;

  }

  .el-toast__item:nth-child(2) {

    background-color: #595959;

    transform: translateY(-4px) scale(0.95);

    width: calc(100% - 60px);

    z-index: 1990;

  }

  .el-toast__item:nth-child(3) {

    background-color: #767676;

    transform: translateY(0) scale(0.9);

    width: calc(100% - 60px);

    z-index: 1980;

  }

  .el-toast--example {

    left: 0;

    top: calc(50% - 33px);

  }

}



.avatar-group img {

  max-width: 50px;

  border-radius: 100px;

  border: 2px solid var(--light);

  background-color: var(--label);

}



.avatar-group .avatar-list-btn {

  padding: 5px;

  color: #fff;

  border: 3px solid var(--light) !important;

  background-color: var(--label);

  border-radius: 50px;

  min-width: 50px;

  min-height: 50px;

}



.avatar-list {

  background-color: rgba(255, 255, 255, .4);

  backdrop-filter: blur(4px);

}



.avatar-group .avatar:not(:first-child) {

  margin-left: -15px;

}



.avatar-group .avatar[class*=avatar-] i {

  border-radius: 50%;

  width: 11px;

  height: 11px;

  position: absolute;

  left: 8px;

  bottom: 0;

  border: 1px solid #FFF;

  z-index: 299;

}



.avatar[class*=avatar-] i {

  border-radius: 50%;

  width: 11px;

  height: 11px;

  position: absolute;

  left: 20px;

  bottom: 0;

  border: 1px solid #FFF;

  z-index: 299;

}



.avatar.avatar-offline i {

  background-color: #BABFC7;

}


.avatar.avatar-online i {

  background-color: #16D39A;

}

.profile-submenu .avatar i{
  left: 0px;
}

.group-over-5 {

  max-height: 50px;

  max-width: 50px;

}



.avatar {

  position: relative;

}





/******checkbox*/



.state .icon img {

  max-width: 12px;

  margin-top: -9px;

}

.product-header-tabs button {
  background-color: white;
}

div.table-legend > div > button {
  background-color: white;
}

#preloader-overlay-global-search img {

  height: 20vh;

  width: auto;

  margin: 20px;

}


#preloader-overlay-global-search  {

  display: flex;

  z-index: 999;

  top: 0;

  right: 0;

  left: 0;

  bottom: 0;

  justify-content: center;

  align-items: center;

  background-color: rgba(255, 255, 255);

}

.global-seearch-no-elements {
  text-align: center;
  margin-bottom: 20px;
}

.ac-filters button {

  color: rgb(0,0,0);
  background: rgb(239, 239, 239) !important;
  margin: 10px;

}

.ac-filters button.active {

  color: white !important;
  background: #152856 !important;

}

.print-only{
  display: none;
}

@media print {
  .no-print {
      display: none;
  }

  .print-only{
      display: block;
  }
}