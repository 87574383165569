    /* Small devices (landscape phones, 576px and up) */

    

    @media (min-width: 576px) {}

    /* Medium devices (tablets, 768px and up) */

    

    @media (min-width: 768px) {

        #filter-list .row .col-md-6:first-child {

            padding-right: 0!important;

        }

        #search-form {

            display: flex !important;

        }

        #table-warehouse-barcodes {

            display: table;

        }

    }

    /* Large devices (desktops, 992px and up) */

    

    @media (min-width: 992px) {

    }

    /***bootstrap on MS Edge & IE fix ***/

    @media all and (-ms-high-contrast: none), (min-width: 992px), (-ms-high-contrast: active), @supports (-ms-accelerator:true) or (-ms-ime-align:auto) {

        .col-1, 

        .col-sm-1, 

        .col-md-1, 

        .col-lg-1, 

        .col-xl-1{

            width: 8.333333%;

        } 



        .col-2,

        .col-sm-2,

        .col-md-2,

        .col-lg-2,

        .col-xl-2{

            width: 16.666667%;

        }



        .col-3, 

        .col-sm-3, 

        .col-md-3, 

        .col-lg-3, 

        .col-xl-3{

        width: 25%;

        } 



        .col-4, 

        .col-sm-4, 

        .col-md-4, 

        .col-lg-4, 

        .col-xl-4{

            width: 33.333333%;

        } 



        .col-5, 

        .col-sm-5, 

        .col-md-5, 

        .col-lg-5, 

        .col-xl-5{



            width: 41.666667%;

        } 



        .col-6, 

        .col-sm-6, 

        .col-md-6, 

        .col-lg-6, 

        .col-xl-6{

        width: 50%;

        } 



        .col-7,

        .col-sm-7,

        .col-md-7,

        .col-lg-7,

        .col-xl-7{

        width: 58.333333%;

        }



        .col-8, 

        .col-sm-8, 

        .col-md-8, 

        .col-lg-8, 

        .col-xl-8{

            width: 66.666667%;

        } 



        .col-9,

        .col-sm-9,

        .col-md-9,

        .col-lg-9,

        .col-xl-9{

        width: 75%;

        }



        .col-10, 

        .col-sm-10, 

        .col-md-10, 

        .col-lg-10, 

        .col-xl-10{

            width: 83.333333%;



        } 



        .col-11, 

        .col-sm-11, 

        .col-md-11, 

        .col-lg-11, 

        .col-xl-11{

        width: 91.666667%;

        } 



        .col-12, 

        .col-sm-12, 

        .col-md-12, 

        .col-lg-12, 

        .col-xl-12{

        width: 100%;

        } 

    }

    /* X-Large devices (large desktops, 1200px and up) */

    

    @media (min-width: 1200px) {}

    /* XX-Large devices (larger desktops, 1400px and up) */

    

    @media (min-width: 1400px) {

        .autocomplete-form{

            max-width: 40vw;

        }

    }

    /********************************************** MAX ***********************************************/

    /* X-Small devices (portrait phones, less than 576px) */

    

    @media (max-width: 575.98px) {}

    /* Small devices (landscape phones, less than 768px) */

    

    @media (max-width: 767.98px) {

        .onmobile {

            display: block !important;

        }

        .tableonmobile {

            display: table-row !important;

        }

        .nomobile,

        .tablenomobile {

            display: none !important;

        }

        .wms-logo-mobile {

            display: block;

            width: 50vw;

            margin: 20px 0;

        }

        .wms-logo {

            display: none;

        }

        .burgermenu {

            display: block;

        }

        #wmssidebar {

            margin-left: -100vw;

            position: fixed!important;

            z-index: 999;

        }

        .burgertoggled {

            margin-left: 0!important;

        }

        #search-bar,

        .title-bar {

            height: auto;

        }

        #search-form {

            display: none !important;

        }

        #search-bar {

            flex-direction: column-reverse;

        }

        .title-bar .flex-no-wrap .flex-no-wrap {

            flex-wrap: wrap !important;

            /* width: 100% !important; */

            justify-content: flex-end !important;

        }



        .title-bar .flex-no-wrap button{

            width: 30%;

        }



        .title-bar .flex-no-wrap button:nth-child(3),

        .title-bar .flex-no-wrap button:nth-child(4) {

            margin-top: 10px;

        }

        .profile-submenu {

            margin: 20px;

            width: 100%;

            /* float: left; */

        }

        #dingding-container .dropdown-header,

        .dingding-list {

            width: 97vw;

        }

        #search-form .form-group,

        #search-form .form-group:hover,

        #search-form .form-group.hover {

            width: 100%;

            margin-bottom: 10px;

        }

        .title-bar {

            padding: 20px 0;

        }

        .title-bar .btn-wms-light div {

            display: none;

        }

        #search-bar .justify-content-end {

            width: 85%;

            align-self: flex-start;

            padding-right: 0!important;

        }

        .menu-item h5 {

            font-size: 19px;

            letter-spacing: -.5px;

            top: 0;

            margin-left: 20px;

            opacity: 1;

        }

        .menu-item {

            flex-wrap: nowrap;

            justify-content: flex-start;

            flex-direction: row;

            width: 80vw;

            padding: 20px;

        }

        .menu-wrapper {

            padding-bottom: 60px;

        }

        .menu-item.active {

            padding-left: 20px;

        }

        .logo-section {

            box-shadow: none;

        }

        #content-container-right {

            padding: 10px 10px 80px 10px!important;

        }

        /*glowna*/

        .sparkboxes .box {

            margin: 10px 0;

        }

        .shortcuts-front {

            margin-top: 20px;

        }

        .scrolling h2 {

            margin-top: 20px;

        }

        .rounded-10 {

            border-radius: 0;

        }

        #filter-sidebar {

            margin-top: -150vh;

            opacity: 0;

        }

        #date-filter-container #current-filter {

            width: 100%;

            text-align: center;

        }

        #report-form-container #date-filter-container {

            display: block;

        }

        #date-filter-container {

            width: 100%;

            margin-top: 10px;

        }

        #filter-sidebar.toggled {

            margin-top: 0;

            opacity: 1;

            margin-left: 0;

            position: absolute;

            top: 0px;

            z-index: 9999;

            height: 100vh;

            width: 100vw;

            overflow-y: scroll;

            margin-bottom: 200px;

            padding-bottom: 100px!important;

        }

        #filter-toggle {

            display: none;

        }

        #filter-toggle-mobile,

        #filter-toggle3,

        #filter-toggle2 {

            display: block;

        }

        #filter-toggle3 img {

            width: 40px;

            height: 40px;

            float: right;

        }

        #filter-toggle3 {

            float: right;

            align-self: flex-end;

        }

        .table-legend,

        #current-day,

        #current-week,

        #current-month {

            display: none;

        }

        #pagination-container {

            margin-bottom: 40px;

        }

        .document-details-container .row {

            margin-left: 0;

            margin-right: 0;

        }

        .document-details div div {

            justify-content: flex-start;

        }

        .document-details .has-float-label {

            width: auto;

        }

        .product-missing th,

        .product-missing td {

            background-color: #c7171d !important;

        }

        #table-documents thead tr th:last-child,

        #table-documents tbody tr td:last-child {

            display: none;

        }

        #table-warehouses tbody th {

            min-width: 220px;

        }

        .title-bar button img {

            margin: 0;

        }

        .page-title #page-title {

            font-size: 18px !important;

        }

        .title-bar button {

            font-size: 0px;

            line-height: 0px;

            height: 36px;

        }

        .content-container {

            max-height: 100% !important;

        }

        .product-details-container .col-sm-12 {

            justify-content: center;

            margin-right: 0 !important;

            margin-left: 0 !important;

        }

        .specs-list-cont {

            padding-left: 0!important;

            padding-right: 0!important;

        }

        .w-75 {

            width: auto !important;

        }

        .filter-products-cont .form-group:nth-child(2),

        .warehouse-filters .filter-top-bar .form-group:nth-child(2) {

            padding-bottom: 0px !important;

            padding-top: 0px !important;

        }

        .nav-pills .nav-link {

            width: 100%;

            margin: 5px 0;

        }

        .warehouse-spot-cont h2,

        .filter-products-cont #date-filter-container {

            margin: 5px 0 20px;

        }

        .warehouse-spot-cont div {

            margin: 10px 0 0 !important;

        }

        .filter-products-cont .form-group label.form-group,

        .warehouse-filters .filter-top-bar .form-group label.form-group {

            padding: 0 !important;

        }

        .table {

            margin-bottom: 50px;

        }

        .sort-container .filter-top-bar div,

        .sort-container .filter-top-bar .sort-topbar {

            width: 100%;

            padding: 4px 8px !important;

        }

        .filter-top-bar .color-gray,

        .nav-item,

        .filter-top-bar .color-gray div {

            width: 100%;

        }

        .filter-top-bar {

            padding-top: 15px !important;

        }

        .filter-products-cont,

        .filter-top-bar {

            display: flex;

            flex-wrap: wrap;

            max-width: 100vw;

        }

        .filter-top-bar .form-group {

            width: 100%;

            padding: 10px 20px!important;

        }

        .filter-top-bar .custom-checkbox {

            padding-bottom: 20px!important;

        }

        .timeline .timeline-container .timeline-body {

            max-width: 60vw;

        }

        .py-5.my-5 {

            margin-top: 15px !important;

            margin-bottom: 15px !important;

            padding-top: 15px !important;

            padding-bottom: 15px !important;

        }

        .upload-user-photo {

            margin-bottom: 30px;

        }

        .profile-container>.pr-0,

        .report-problem .pr-0 {

            padding-right: 15px !important;

        }

        .profile-container .col-sm-8>.px-3 {

            padding: 0 !important;

        }

        .report-tiles-mobile {

            display: block;

        }

        .report-tiles,

        .dropleft span {

            display: none !important;

        }

        #settings-form-auto-accept.settings-form .px-3,

        #settings-form-auto-accept .col-md-12 {

            padding-right: 0 !important;

            padding-left: 0 !important;

        }

        .product-add-container2 .ml-3 {

            margin-left: 0 !important;

        }

        .product-add-container2 .pr-0,

        .user-add-container .pr-0 {

            padding-right: 15px !important;

        }

        .product-add-container2 .px-0,

        .user-add-container .px-0 {

            padding-right: 15px !important;

            padding-left: 15px !important;

        }

        .product-add-container2 .form-group.pl-3 {

            padding-left: 0px !important;

        }

        #additional-mail {

            margin-top: 15px;

        }

        .btn {

            padding: 6px 5px 5px 5px;

        }

        .warehouse-filters h2 .badge {

            margin: 15px auto 0 !important;

            display: block;

        }

        #table-barcodes h3 span {

            display: block;

        }

        .warehouse-filters #tab2 .table .document-group,

        .product-details-container #tab2 .table .document-group {

            padding: 15px 0 !important;

        }

        .warehouse-filters #tab2 .thead-light th:first-child {

            /* display: none; */

        }

        #pass-change .modal-footer button{

            margin: 10px 0 0 0!important;

            width: 100% !important;

        }



        #pass-change .modal-body .col-sm-12{

            padding: 0 !important;

        }



        .modal-dialog .modal-content{

            max-width: 95vw;

        }

        .warehouse-filters #tab2 .thead-light th {

            width: max-content;

        }

        .product-details-container #tab2 h3.text-left {

            margin-top: 30px !important;

            margin-bottom: 0 !important;

        }

        .product-details-container #tab2 .table.my-4 {

            margin-top: 0 !important;

            margin-bottom: 0 !important;

        }

        .warehouse-filters #tab2 .thead-light {

            flex-direction: row;

            width: 100%;

            flex-wrap: nowrap;

        }

        .add-tab {

            display: none;

        }

        .add-tab.active {

            display: block;

        }

        .category-adder {

            flex-wrap: wrap;

        }

        .category-adder .has-float-label {

            padding: 0 !important;

        }

        .product-add-container {

            margin-bottom: 15px;

            padding-right: 0 !important;

            padding-left: 0 !important;

        }

        #date-valid{

            padding: .375rem .55rem; 

        }

        .spec-list-cont .col-sm-12{

            padding-right: 0 !important;

        }

        .product-add-container .mr-3{

            margin-right: 0 !important;

        }

        .product-add-container .category-adder{

            margin-bottom: 10px;

        }

        .category-adder .scrolling{

            max-height: inherit;

        }

        #table-users .text-right button {

            margin-bottom: 10px;

        }

        .report-container .report-form {

            flex-wrap: wrap;

        }

        .report-form .my-0 {

            margin-left: 15px !important;

            margin-right: 15px !important;

        }

        .autocomplete-form {

            max-width: 100vw;

        }

        .ac-link,

        .ac-type {

            display: none;

        }

        .ac-name {

            text-align: center;

        }

        .ac-item {

            flex-direction: column;

        }

        .has-float-label .form-control:placeholder-shown:not(:focus)+* {

            font-size: 90%;

            letter-spacing: -.6px !important;

        }

        .col-sm-12 {

            flex-grow: 1;

        }

        .input-group .has-float-label label {

            font-size: 80%;

            letter-spacing: -.6px !important;

        }

        .input-group-append .input-group-text {

            font-size: 90%;

            letter-spacing: -.6px !important;

            padding: 6px;

        }

        .avatar[class*=avatar-] i {

            bottom: 50px;

        }

        .roles-container .filter-top-bar .form-group,

        .roles-container .filter-top-bar .col-md-4,

        .role-add-container .col-md-10 .col-sm-12 {

            padding-left: 0 !important;

            padding-right: 0 !important;

        }

        .role-add-container .card {

            border: 0!important;

        }

        .role-import-btn {

            width: 100%;

            margin-top: 20px !important;

        }

        .warehouse-spot-cont h2 {

            display: flex;

            flex-wrap: wrap;

        }

        .warehouse-spot-cont h2 div:last-child {

            margin-left: 20px;

        }

        .product-details-container .pr-3{

            padding-right: 0 !important;

        }

        #content-container-right.pr-2 {

            padding-right: 0.5rem !important;

        }

        .doc-edit .pr-0{

            padding-left: 0 !important;

        }

        .product-list-item{

            padding: 0 !important;

        }

        .profile-submenu .avatar i {
            display: none;
        }

    }

    /* Medium devices (tablets, less than 992px) */

    

    @media (max-width: 991.98px) {

        input[type=text] {   

            /* Remove First */

            -webkit-appearance: none;

            -moz-appearance: none;

            appearance: none;

        }        

        /* #filter-sidebar{

            min-width: 340px;

        }

        #filter-sidebar.toggled {

            margin-left: -300px;

        } */

    }

    /* Large devices (desktops, less than 1200px) */

    

    @media (max-width: 1199.98px) {}

    /* X-Large devices (large desktops, less than 1400px) */

    

    @media (max-width: 1399.98px) {

        

        .sort-topbar {

            padding-right: 20px;

            font-size: 13px;

        }

        .table-legend a{

            font-size: 12px;

            letter-spacing: -.3px;

        }

        #current-filter span{

            display: none;

        }

        .report-tiles>.report-tile-container {

            margin: 10px;

            width: 18vw;

        }

        .settings .report-tiles>.report-tile-container {

            margin: 10px;

            width: 24vw;

        }

        .report-tiles{

            flex-wrap: wrap;

        }

        .report-tile{

            max-width: inherit;

        }

        

.product-add-container2 label{

    letter-spacing: -1px;

}

    }